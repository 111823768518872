import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const isProduction = process.env.NODE_ENV === 'production';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: !isProduction,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
