import { ReactElement } from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  padding: ${(props) => props.theme.space.xxs};
  background-color: ${(props) => props.theme.color.darkGrayBlue};
  color: ${(props) => props.theme.color.darkBlue};
  display: flex;
  justify-content: center;
`;

export default function Footer(): ReactElement {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <span>Copyright © {currentYear} how-to-read.me</span>
    </FooterContainer>
  );
}
