import { ReactElement } from 'react';
import styled from 'styled-components';
import isNull from 'lodash.isnull';
import isUndefined from 'lodash.isundefined';

import { Answer, QuestionnaireItem } from 'types';
import Card from 'components/card';
import { Text } from 'components/styled';
import usePostAnswer from 'hooks/usePostAnswer';
import useUpdateAnswer from 'hooks/useUpdateAnswer';

import ScaleAnswer from './ScaleAnswer';
import AnswerStatus from './AnswerStatus';

type Props = {
  questionnaireId: string;
  item: QuestionnaireItem;
  answer?: Answer;
  id: string;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export default function QuestionItem({
  questionnaireId,
  item,
  answer,
  id,
}: Props): ReactElement {
  const isAnswered = !isUndefined(answer) && !isNull(answer.scale_value);
  const postAnswerMutation = usePostAnswer(questionnaireId, item.id);
  const updateAnswerMutation = useUpdateAnswer(questionnaireId, item.id);

  const isLoading =
    postAnswerMutation.isLoading || updateAnswerMutation.isLoading;
  const isError = postAnswerMutation.isError || updateAnswerMutation.isError;
  const isSuccess =
    postAnswerMutation.isSuccess || updateAnswerMutation.isSuccess;

  const handleAnswer = (index: number) => {
    const mutation = isUndefined(answer)
      ? postAnswerMutation
      : updateAnswerMutation;

    mutation.mutate({ scale_value: index, section_id: item.section_id });
  };

  return (
    <Card hasError={!isAnswered} id={id}>
      <Container>
        <Text type="primary" fontFamily="serif">
          {item.text}
        </Text>
        <ScaleAnswer
          length={item.scale_length}
          onAnswer={handleAnswer}
          initialAnswer={answer?.scale_value}
          disabled={isLoading}
        />
        <AnswerStatus
          isLoading={isLoading}
          isError={isError}
          isSuccess={isAnswered || isSuccess}
        />
      </Container>
    </Card>
  );
}

QuestionItem.defaultProps = {
  answer: undefined,
};
