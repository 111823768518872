/* eslint prefer-destructuring: 0 */

import {
  BreakpointsTheme,
  SpaceTheme,
  SizeTheme,
  FontSizesTheme,
} from './types';

const addUnit = (value: string | number, unit = 'px') => `${value}${unit}`;

const oldTheme = {
  fontFamily: {
    sansSerif: "'DM Sans', sans-serif",
    serif: "'Source Serif Pro', serif",
  },
  color: {
    strongCyan: '#11d0ad',
    pureOrange: '#f0b000',
    softRed: '#f85656',
    moderateViolet: '#9b5fce',
    brightBlue: '#297def',
    grayBlue: '#f0f6fc',
    grayBlue2: '#c5c9cD',
    darkGrayBlue: '#e6eef8',
    darkBlue: '#788aa2',
    black: '#191919',
    darkGray: '#888888',
    gray: '#aaaaaa',
    lightGray: '#f5f5f5',
    lightGray2: '#f2f2f2',
    white: '#ffffff',
    limeGreen: '#11d03d',
  },
  fontSize: {
    xxs: '14px',
    xs: '16px',
    sm: '16px',
    md: '16px',
    lg: '18px',
    xl: '24px',
  },
  space: {
    xxs: '8px',
    xs: '16px',
    sm: '24px',
    md: '32px',
    lg: '48px',
    xl: '64px',
  },
  breakpoint: {
    desktop: '768px',
  },
};

const space: SpaceTheme = [
  0, // 0
  4, // 1
  8, // 2
  12, // 3
  16, // 4
  20, // 5
  24, // 6
  28, // 7
  32, // 8
  36, // 9
  40, // 10
  44, // 11
  48, // 12
  64, // 13
  96, // 14
  128, // 15
];
// space aliases
space.xxs = addUnit(space[2]); // 8
space.xs = addUnit(space[4]); // 16
space.sm = addUnit(space[6]); // 24
space.md = addUnit(space[8]); // 32
space.lg = addUnit(space[12]); // 48
space.xl = addUnit(space[13]); // 64

const breakpoints: BreakpointsTheme = [
  0,
  '435px',
  '576px',
  '768px',
  '992px',
  '1200px',
];

breakpoints.desktop = breakpoints[3];

const sizes: SizeTheme = [
  0, // 0
  8, // 1
  16, // 2
  24, // 3
  32, // 4
  36, // 5
  48, // 6
  64, // 7
  128, // 8
  256, // 9
  512, // 10
  600, // 11
  768, // 12
  990, // 13
  1024, // 14
  1168, // 15
  1536, // 16
];
// aliases
sizes.xs = sizes[1];
sizes.sm = sizes[2];
sizes.md = sizes[3];
sizes.lg = sizes[4];
sizes.xl = sizes[6];

const fontSizes: FontSizesTheme = [
  8, // 0
  10, // 1
  12, // 2
  14, // 3
  16, // 4
  18, // 5
  20, // 6
  24, // 7
  32, // 8
  36, // 9
  40, // 10
  48, // 11
];

// aliases
fontSizes.xxs = fontSizes[3]; // 14px
fontSizes.xs = fontSizes[4]; // 16px
fontSizes.sm = fontSizes[4]; // 16px
fontSizes.md = fontSizes[4]; // 16px
fontSizes.lg = fontSizes[5]; // 18px
fontSizes.xl = fontSizes[7]; // 24px

export const lightTheme = {
  ...oldTheme,
  breakpoints,
  fontSizes,
  space,
  sizes,
  fonts: { ...oldTheme.fontFamily },
  colors: {
    ...oldTheme.color,
  },
};

export default lightTheme;
