import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { updateAnswer } from 'services/questionnaires';
import { Answer, AnswerPayload } from 'types';

export default function useUpdateAnswer(
  questionnaireId: string,
  itemId: string,
): UseMutationResult<Answer, AxiosError, AnswerPayload> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => updateAnswer(questionnaireId, itemId, payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries('questionnaire');
      },
    },
  );
}
