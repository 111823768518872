import { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Box, Text, Button } from 'components/styled';
import { useTranslation } from 'react-i18next';

export default function Login(): ReactElement {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation('login');

  return (
    <div>
      <Box>
        <Text size="md" type="primary" lineHeight={3}>
          {t('title')}
        </Text>
        <Text
          size="xs"
          type="secondary"
          fontWeight="normal"
          style={{
            marginBottom: 16,
          }}
        >
          {t('subtitle')}
        </Text>
        <Button
          variant="primary"
          minWidth="140px"
          onClick={() => loginWithRedirect()}
        >
          {t('login')}
        </Button>
      </Box>
    </div>
  );
}
