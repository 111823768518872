import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SectionList from 'components/section/SectionList';
import { PageLoader } from 'components/loaders';
import { Box, Text, Button } from 'components/styled';
import usePublicReadme from 'hooks/usePublicReadme';

export default function PublicReadme(): ReactElement {
  const { t } = useTranslation('readme');
  const { readmeId } = useParams<{ readmeId: string }>();
  const readme = usePublicReadme(readmeId);

  if (readme.isLoading || readme.isIdle) {
    return <PageLoader />;
  }

  if (readme.isError) {
    return <p>{readme.error.message}</p>;
  }

  return (
    <div>
      <Box style={{ position: 'relative' }}>
        <Text size="sm" type="primary" fontWeight="bold" lineHeight={3}>
          {t('title')}
        </Text>
      </Box>
      <SectionList
        sections={readme.data?.sections}
        answers={readme.data?.answers}
      />
      <a href="https://www.how-to-read.me" target="_blank" rel="noreferrer">
        <Button
          variant="primary"
          fullWidth
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          {t('createYourOwn')}
        </Button>
      </a>
    </div>
  );
}
