import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Text, Tag, Button, ButtonGroup } from 'components/styled';
import IconButton from 'components/iconButton';
import SectionList from 'components/section/SectionList';
import Loading from 'components/loading';
import { PageLoader } from 'components/loaders';
import { createReadme } from 'services/questionnaires';
import useCustomisationData from 'hooks/useCustomisationData';

export default function Customisation(): ReactElement {
  const { t } = useTranslation('customisation');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const customisation = useCustomisationData();

  const handleSubmit = () => {
    setIsLoading(true);
    createReadme();
    setTimeout(() => history.push('/'), 3000);
  };

  const handleReturn = () => {
    history.push('/questionnaire');
  };

  if (customisation.isLoading || customisation.isIdle) {
    return <PageLoader />;
  }

  if (customisation.isError) {
    return <p>{customisation.error.message}</p>;
  }

  return (
    <div>
      <Box style={{ position: 'relative' }}>
        <IconButton
          icon="arrow-left"
          onClick={handleReturn}
          style={{
            position: 'absolute',
            left: 0,
          }}
        />
        <Tag color="strongCyan">{t('tag')}</Tag>
        <Text size="sm" type="primary" fontWeight="bold" lineHeight={3}>
          {t('title')}
        </Text>
        <Text size="xxs" type="secondary" fontWeight="normal">
          {t('estimation')}
        </Text>
      </Box>
      <SectionList
        sections={customisation.data.sections}
        answers={customisation.data.answers}
        isEditMode
      />
      <ButtonGroup>
        <IconButton size={46} icon="arrow-left" onClick={handleReturn} />
        <Button variant="primary" fullWidth onClick={handleSubmit}>
          {t('submit')}
        </Button>
      </ButtonGroup>
      <Loading isVisible={isLoading} />
    </div>
  );
}
