import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import { Color, AnswerWithItem } from 'types';
import Modal from 'components/modal';
import { ButtonGroup, Button } from 'components/styled';
import {
  ModalHeader,
  ModalTitle,
  ModalTitleText,
  ModalAccent,
  ModalCloseButton,
  ModalBody,
  StyledInput,
  StyledTextarea,
  StyledForm,
} from './styled';

type Props = {
  isOpen: boolean;
  color: Color;
  answer: AnswerWithItem;
  onSave: (title: string, description: string) => void;
  onCancel: () => void;
};

type FormValues = {
  title: string;
  description: string;
};

export default function PhraseEditor({
  isOpen,
  color,
  answer,
  onSave,
  onCancel,
}: Props): ReactElement {
  const hasContent = Boolean(answer.item.text);
  const { t } = useTranslation('customisation');

  const formik = useFormik({
    initialValues: {
      title: answer?.item.text,
      description: answer?.sub_text || '',
    },
    onSubmit: (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>,
    ) => {
      onSave(values.title, values.description);
      setSubmitting(false);
    },
  });

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <ModalTitle>
          <ModalTitleText>
            {t(hasContent ? 'editPhrase' : 'customisePhrase')}
          </ModalTitleText>
          <ModalAccent color={color} />
        </ModalTitle>
        <ModalCloseButton icon="close-icon" onClick={onCancel} variant="dark" />
      </ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledInput
            id="title"
            name="title"
            placeholder={t('titlePlaceholder')}
            disabled
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <StyledTextarea
            id="description"
            name="description"
            placeholder={t('descriptionPlaceholder')}
            rows={10}
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          <ButtonGroup>
            <Button variant="secondary" minWidth="140px" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button variant="primary" minWidth="140px" type="submit">
              {t('save')}
            </Button>
          </ButtonGroup>
        </StyledForm>
      </ModalBody>
    </Modal>
  );
}
