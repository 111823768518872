import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { getCustomisationData } from 'services/questionnaires';
import { Readme } from 'types';

export default function useCustomisationData(): UseQueryResult<
  Readme,
  AxiosError
> {
  return useQuery('customisation', getCustomisationData);
}
