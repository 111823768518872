import isNull from 'lodash.isnull';

import { Answer, QuestionnaireItem } from 'types';

export function isQuestionnaireComplete(
  questions: QuestionnaireItem[],
  answers: Answer[],
): boolean {
  return (
    answers.filter(({ scale_value }) => !isNull(scale_value)).length ===
    questions.length
  );
}

export default {};
