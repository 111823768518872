import {
  ActiveQuestionnaire,
  Answer,
  AnswerPayload,
  Questionnaire,
  QuestionnaireItem,
  Section,
  Readme,
} from 'types';

import apiClient from './api';
import { extractData } from './utils';

export function getQuestionnaires(): Promise<Questionnaire[]> {
  return apiClient.instance.get('/questionnaires').then(extractData);
}

export function getAnswers(
  questionnaireId: Questionnaire['id'],
): Promise<Answer[]> {
  return apiClient.instance
    .get(`/questionnaires/${questionnaireId}/answers`)
    .then(extractData);
}

export async function getActiveQuestionnaire(): Promise<ActiveQuestionnaire> {
  const questionnaires = await getQuestionnaires();
  const activeQuestionnaire = questionnaires.find(({ active }) => active);

  if (!activeQuestionnaire) {
    throw Error('No active questionnaire found');
  }

  const answers = await getAnswers(activeQuestionnaire.id);

  return apiClient.instance
    .get(`/questionnaires/${activeQuestionnaire.id}/items`)
    .then(extractData)
    .then((items) => ({
      ...activeQuestionnaire,
      items,
      answers,
    }));
}

export function getAnswer(
  questionnaireId: Questionnaire['id'],
  itemId: QuestionnaireItem['id'],
): Promise<Answer> {
  return apiClient.instance
    .get(`/questionnaires/${questionnaireId}/items/${itemId}/answer`)
    .then(extractData);
}

export function postAnswer(
  questionnaireId: Questionnaire['id'],
  itemId: QuestionnaireItem['id'],
  answer: AnswerPayload,
): Promise<Answer> {
  return apiClient.instance.post(
    `/questionnaires/${questionnaireId}/items/${itemId}/answer`,
    { data: answer },
  );
}

export function updateAnswer(
  questionnaireId: Questionnaire['id'],
  itemId: QuestionnaireItem['id'],
  answer: AnswerPayload,
): Promise<Answer> {
  return apiClient.instance.put(
    `/questionnaires/${questionnaireId}/items/${itemId}/answer`,
    { data: answer },
  );
}

export function setAnswersOrder(
  questionnaireId: Questionnaire['id'],
  answersOrder: string[],
): Promise<Answer[]> {
  return apiClient.instance.post(
    `/questionnaires/${questionnaireId}/orderanswers`,
    { data: answersOrder },
  );
}

export function getSections(): Promise<Section[]> {
  return apiClient.instance.get('/sections').then(extractData);
}

export function getCustomisationData(): Promise<Readme> {
  return apiClient.instance.get('/customisation').then(extractData);
}

export function getReadme(): Promise<Readme> {
  return apiClient.instance.get('/readme').then(extractData);
}

export function createReadme(): Promise<Readme> {
  return apiClient.instance
    .post('/readme', { data: { public: true } })
    .then(extractData);
}

export function getPublicReadme(id: string): Promise<Readme> {
  return apiClient.instance
    .get(`/publicreadme/${id}`, { withToken: false })
    .then(extractData);
}

export default {};
