import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  component: React.FC;
  path: string;
  exact: boolean;
}

export default function ProtectedRoute({
  component: Component,
  path,
  exact,
}: Props): ReactElement {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        isAuthenticated ? <Component /> : <Redirect to="/login" />
      }
    />
  );
}
