import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import logo from 'assets/images/logo.svg';
import { Button } from 'components/styled';

const HeaderWrapper = styled.header`
  width: 100%;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const HeaderResponsiveContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space.sm};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
    width: ${(props) => props.theme.breakpoint.desktop};
    margin: 0 auto;
  }
`;

const LogoutButton = styled(Button)`
  padding: ${(props) => props.theme.space.xxs};
  position: absolute;
  right: ${(props) => props.theme.space.sm};
`;

export default function Header(): ReactElement {
  const { t } = useTranslation('common');
  const { logout, isAuthenticated } = useAuth0();

  return (
    <HeaderWrapper>
      <HeaderResponsiveContainer>
        <a href="/">
          <img src={logo} alt="readme" />
        </a>
        {isAuthenticated && (
          <LogoutButton
            variant="secondary"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {t('logout')}
          </LogoutButton>
        )}
      </HeaderResponsiveContainer>
    </HeaderWrapper>
  );
}
