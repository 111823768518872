import axios, { AxiosInstance } from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    withToken: boolean;
  }
}

export class Client {
  instance: AxiosInstance;

  tokenGenerator: () => Promise<string>;

  constructor() {
    this.tokenGenerator = () => new Promise(() => '');
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withToken: true,
    });

    this.instance.interceptors.request.use(
      async (config) => {
        if (!config.withToken) {
          return config;
        }
        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      },
    );

    return this;
  }

  setTokenGenerator(tokenGenerator: () => Promise<string>): Client {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken(): Promise<string> {
    return this.tokenGenerator();
  }
}

const result = new Client();
export default result;
