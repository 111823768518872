import { ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';

import './Modal.css';

type Props = {
  isOpen: boolean;
  children: ReactNode;
};

const customStyles = {
  overlay: {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
    border: 'none',
    borderRadius: '10px',
    padding: '0px',
  },
};

ReactModal.setAppElement('#root');

export default function Modal({ isOpen, children }: Props): ReactElement {
  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      {children}
    </ReactModal>
  );
}
