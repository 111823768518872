import { Color } from 'types';

export const sectionColors: Color[] = [
  'strongCyan',
  'pureOrange',
  'moderateViolet',
  'softRed',
  'brightBlue',
  'limeGreen'
]

export default [];
