import { ReactElement, ChangeEventHandler, useRef } from 'react';
import styled from 'styled-components';

import { Box } from '../box';

type CheckboxProps = {
  color?: string;
  checked?: boolean;
  ml?: string | number | Record<string, string | number>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  children?: never;
};

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  /* clippath: inset(50%); */
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CustomCheckbox = styled(Box)`
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px black;
  }
`;

export const Checkbox = ({
  checked = false,
  color = 'green',
  onChange,
  ...props
}: CheckboxProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box {...props}>
      <HiddenCheckbox ref={inputRef} checked={checked} onChange={onChange} />
      <CustomCheckbox
        size="lg"
        bg={checked ? color : 'white'}
        checked={checked}
        borderRadius="4px"
        transition="all 150ms"
        border={1}
        borderStyle="solid"
        borderColor={checked ? 'transparent' : color}
        onClick={() => inputRef?.current?.click()}
      >
        {checked && (
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        )}
      </CustomCheckbox>
    </Box>
  );
};
