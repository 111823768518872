import { ReactElement, useState, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';

import useAnswersOrder from 'hooks/useAnswersOrder';
import useActiveQuestionnaire from 'hooks/useActiveQuestionnaire';
import { Color, AnswerWithItem } from 'types';
import AnswerEditor from './AnswerEditor';

const Container = styled.div`
  width: 100%;
`;

const StyledDraggable = styled(Draggable)`
  outline: none;

  &:focus {
    outline: none;
  }
`;

type Props = {
  color: Color;
  answers: AnswerWithItem[];
};

export default function AnswersEditor({ answers, color }: Props): ReactElement {
  const [renderAnswers, setRenderAnswers] = useState(answers);
  const questionnaire = useActiveQuestionnaire();
  const updateAnswerMutation = useAnswersOrder(questionnaire?.data?.id || '');

  useEffect(() => {
    setRenderAnswers(answers);
  }, [answers]);

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return null;
    }

    if (destination.index === source.index) {
      return null;
    }

    const draggableAnswer = renderAnswers.find(({ id }) => id === draggableId);
    if (!draggableAnswer) {
      return null;
    }

    const nextAnswers = [...answers];
    nextAnswers.splice(source.index, 1);
    nextAnswers.splice(destination.index, 0, draggableAnswer);

    updateAnswerMutation.mutate(nextAnswers.map(({ id }) => id));

    return null;
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="title">
          {(providedDroppable) => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              {renderAnswers.map((answer, index) => (
                <StyledDraggable
                  draggableId={answer.id}
                  key={answer.id}
                  index={index}
                >
                  {(providedDraggable, snapshot) => (
                    <div
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      ref={providedDraggable.innerRef}
                    >
                      <AnswerEditor
                        isDragging={snapshot.isDragging}
                        answer={answer}
                        color={color}
                      />
                    </div>
                  )}
                </StyledDraggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}
