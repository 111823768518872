import styled from 'styled-components';

import { Color } from 'types';
import IconButton from 'components/iconButton';

export const ModalTitle = styled.div`
  width: auto;
`;

export const ModalTitleText = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const ModalHeader = styled.div`
  min-width: 320px;
  width: 100%;
  position: relative;
  padding: ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs}
    ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs};
  display: flex;
  justify-content: center;
  border-bottom: ${(props) => `1px solid ${props.theme.color.lightGray}`};
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ModalAccent = styled.div<{ color: Color }>`
  width: 100%;
  height: 2px;
  margin-top: 6px;
  background-color: ${(props) => props.theme.color[props.color]};
`;

export const ModalCloseButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.space.xs};
  top: ${(props) => props.theme.space.md};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.input`
  border-radius: 6px;
  border: ${(props) => `1px solid ${props.theme.color.lightGray2}`};
  padding: ${(props) => props.theme.space.sm};
  margin-bottom: ${(props) => props.theme.space.xs};
  font-family: ${(props) => props.theme.fontFamily.serif};
  font-size: ${(props) => props.theme.fontSize.xs};
  outline: none;
  box-shadow: none;

  &:active {
    border: ${(props) => `1px solid ${props.theme.color.lightGray2}`};
  }
`;

export const StyledTextarea = styled.textarea`
  border-radius: 6px;
  border: ${(props) => `1px solid ${props.theme.color.lightGray2}`};
  padding: ${(props) => props.theme.space.sm};
  margin-bottom: ${(props) => props.theme.space.xs};
  font-family: ${(props) => props.theme.fontFamily.serif};
  font-size: ${(props) => props.theme.fontSize.xs};
  outline: none;
  box-shadow: none;

  &:active {
    border: ${(props) => `1px solid ${props.theme.color.lightGray2}`};
  }
`;
