export function mapStyles(
  prop: string | undefined,
  map: Record<string, string>,
): string {
  const keys = Object.keys(map);
  if (!prop) {
    return map[keys[0]];
  }

  return map[prop];
}

export default {};
