import { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text, Graphic } from 'components/styled';
import Modal from 'components/modal';
import wandIcon from 'assets/images/wand-icon.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${(props) => props.theme.space.sm};
  text-align: center;
`;

const AnimatedIcon = styled.img`
  animation-name: twinkle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

type Props = {
  isVisible: boolean;
};

export default function Loading({ isVisible }: Props): ReactElement {
  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isVisible}>
      <Container>
        <Text size="lg" fontWeight="bold" lineHeight={2}>
          {t('loading.title')}
        </Text>
        <Text>{t('loading.subtitle')}</Text>
        <Graphic color="pureOrange" style={{ marginTop: 16 }}>
          <AnimatedIcon src={wandIcon} alt="loading" />
        </Graphic>
      </Container>
    </Modal>
  );
}
