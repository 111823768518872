import { ReactElement, ReactNode, useState } from 'react';
import { useAtomValue } from 'jotai/utils';

import { Color } from 'types';
import shouldShowErrorAtom from 'store/shouldShowError';

import {
  CardContainer,
  CardHeader,
  CardTitle,
  CardTitleText,
  CardAccent,
  CardCollapseButton,
  CardBody,
} from './styled';

type Props = {
  children: ReactNode;
  title?: string;
  color?: Color;
  hasError?: boolean;
  id?: string;
};

export default function Card({
  children,
  title,
  color = 'strongCyan',
  hasError = false,
  id,
}: Props): ReactElement {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const shouldShowError = useAtomValue(shouldShowErrorAtom);

  const toggleIsCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <CardContainer
      id={id}
      border={1}
      borderColor={shouldShowError && hasError ? 'red' : 'transparent'}
      borderStyle="solid"
    >
      {title && (
        <CardHeader isCollapsed={isCollapsed}>
          <CardTitle>
            <CardTitleText>{title}</CardTitleText>
            <CardAccent color={color} />
          </CardTitle>
          <CardCollapseButton
            icon={isCollapsed ? 'arrow-up' : 'arrow-down'}
            onClick={toggleIsCollapsed}
            variant="dark"
          />
        </CardHeader>
      )}
      {!isCollapsed && <CardBody>{children}</CardBody>}
    </CardContainer>
  );
}

Card.defaultProps = {
  title: null,
  color: 'strongCyan',
  hasError: false,
  id: undefined,
};
