import styled from 'styled-components';
import { color, border, BorderProps, ColorProps } from 'styled-system';

import { Color } from 'types';
import IconButton from 'components/iconButton';

type CardContainerProps = ColorProps | BorderProps;

export const CardContainer = styled.div<CardContainerProps>`
  background: ${(props) => props.theme.color.white};
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: ${(props) => props.theme.space.xs};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${color}
  ${border}
`;

export const CardTitle = styled.div`
  width: auto;
`;

export const CardTitleText = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const CardHeader = styled.div<{ isCollapsed: boolean }>`
  width: 100%;
  position: relative;
  padding: ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs}
    ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs};
  display: flex;
  justify-content: center;
  border-bottom: ${(props) =>
    props.isCollapsed ? 'none' : `1px solid ${props.theme.color.lightGray}`};
`;

export const CardBody = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space.md} ${(props) => props.theme.space.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CardAccent = styled.div<{ color: Color }>`
  width: 100%;
  height: 2px;
  margin-top: 6px;
  background-color: ${(props) => props.theme.color[props.color]};
`;

export const CardCollapseButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.space.xs};
  top: ${(props) => props.theme.space.md};
`;
