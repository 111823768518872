import { ReactElement } from 'react';
import styled from 'styled-components';
import { Text } from 'components/styled';
import { AnswerWithItem } from 'types';

const Container = styled.div`
  width: 100%;
`;

type Props = {
  answers: AnswerWithItem[];
};

export default function AnswersDisplay({ answers }: Props): ReactElement {
  return (
    <Container>
      {answers.map(({ id, item, sub_text }) => (
        <div key={id}>
          <Text fontFamily="serif" style={{ marginBottom: 8 }}>
            {item.text}
          </Text>
          <Text
            fontFamily="serif"
            type="secondary"
            style={{ marginBottom: 24, marginTop: 8 }}
            lineHeight={1.3}
          >
            {sub_text}
          </Text>
        </div>
      ))}
    </Container>
  );
}
