import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider
      audience="https://api.howtoread.me"
      domain={process.env.REACT_APP_AUTH_URL as string}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
      redirectUri={window.location.origin}
    >
      <React.Suspense fallback={null}>
        <React.StrictMode>
          <App />
          <ReactQueryDevtools />
        </React.StrictMode>
      </React.Suspense>
    </Auth0Provider>
  </QueryClientProvider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
