import { useMemo } from 'react';
import { themeGet } from '@styled-system/theme-get';
import { useTheme } from 'styled-components';

export const useThemeGet = ({
  reference,
  defaultValue,
}: {
  reference: string;
  defaultValue?: unknown;
}): unknown => {
  const theme = useTheme();
  return useMemo(() => {
    const value = themeGet(reference, defaultValue)({ theme });

    return value;
  }, [reference, defaultValue, theme]);
};
