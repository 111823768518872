import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Text, Button } from 'components/styled';

import { Checkbox } from 'components/checkbox';

import { Color, AnswerWithItem } from 'types';
import PhraseEditor from './PhraseEditor';
import useUpdateAnswer from './useUpdateAnswer';

const Answer = styled.div<{
  color: Color;
  isDragging: boolean;
}>`
  padding: ${(props) => props.theme.space.xxs};
  border-radius: 6px;
  border: 1px solid;
  border-color: ${(props) => props.theme.color[props.color]};
  margin-bottom: ${(props) => props.theme.space.xs};
  background: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none !important;

  &:hover {
    background: ${(props) => rgba(props.theme.color[props.color], 0.05)};
  }

  ${(props) =>
    props.isDragging &&
    css`
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    `}
`;

const AnswerText = styled.div`
  flex: 1;
  padding: 0 ${(props) => props.theme.space.xxs};
`;

const EditButton = styled(Button)`
  padding: ${(props) => props.theme.space.xxs};
`;

type Props = {
  isDragging: boolean;
  color: Color;
  answer: AnswerWithItem;
};

export default function AnswerEditor({
  color,
  answer,
  isDragging,
}: Props): ReactElement {
  const { t } = useTranslation('customisation');
  const [isEditing, setIsEditing] = useState(false);
  const [isItemEnabled, setIsItemEnabled] = useState(!answer.disabled);

  const updateAnswerMutation = useUpdateAnswer(answer, () => {
    setIsEditing(false);
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = (title: string, sub_text: string) => {
    updateAnswerMutation.mutate({
      sub_text,
      scale_value: answer.scale_value,
      disabled: answer.disabled,
    });
  };

  const handleDisableItem = (disabled: boolean) => {
    updateAnswerMutation.mutate({
      disabled,
      scale_value: answer.scale_value,
      sub_text: answer.sub_text,
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <Answer color={color} isDragging={isDragging}>
      <AnswerText>
        <Text fontFamily="serif" style={{ marginBottom: 8 }}>
          {answer.item.text}
        </Text>
        <Text fontFamily="serif" type="secondary">
          {answer.sub_text}
        </Text>
      </AnswerText>
      <EditButton variant="secondary" onClick={handleEdit}>
        {t('edit')}
      </EditButton>
      <Checkbox
        ml={{ _: 'xxs', desktop: 'xs' }}
        color={color}
        checked={isItemEnabled}
        onChange={(event) => {
          const node = event?.target;
          setIsItemEnabled(node?.checked);
          handleDisableItem(!node?.checked);
        }}
      />
      {isEditing && (
        <PhraseEditor
          isOpen={isEditing}
          color={color}
          answer={answer}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Answer>
  );
}
