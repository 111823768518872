import { ReactElement, MouseEvent } from 'react';
import styled from 'styled-components';
import { mapStyles } from 'components/styled/helpers';

import arrowUpIcon from 'assets/images/arrow-up-icon.svg';
import arrowDownIcon from 'assets/images/arrow-down-icon.svg';
import arrowLeftIcon from 'assets/images/arrow-left-icon.svg';
import burgerIcon from 'assets/images/burger-icon.svg';
import editIcon from 'assets/images/edit-icon.svg';
import closeIcon from 'assets/images/close-icon.svg';
import logoutIcon from 'assets/images/logout-icon.svg';

const iconMap = {
  'arrow-up': arrowUpIcon,
  'arrow-down': arrowDownIcon,
  'arrow-left': arrowLeftIcon,
  'burger-icon': burgerIcon,
  'edit-icon': editIcon,
  'close-icon': closeIcon,
  'logout-icon': logoutIcon,
};

type Variant = 'dark' | 'light' | 'transparent';

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  icon: keyof typeof iconMap;
  variant?: Variant;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
};

const Button = styled.button<{
  size: number | undefined;
  variant: Variant | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: ${(props) =>
    mapStyles(props.variant, {
      light: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
      dark: 'none',
    })};
  background: ${(props) =>
    mapStyles(props.variant, {
      light: props.theme.color.white,
      dark: props.theme.color.lightGray,
      transparent: 'none',
    })};
  border-radius: 4px;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  padding: 0;
  cursor: pointer;
`;

export default function IconButton({
  onClick,
  icon,
  size,
  variant,
  className,
  style,
}: Props): ReactElement {
  return (
    <Button
      onClick={onClick}
      className={className}
      style={style}
      size={size}
      variant={variant}
    >
      <img src={iconMap[icon]} alt={icon} />
    </Button>
  );
}

IconButton.defaultProps = {
  size: 24,
  variant: 'light',
  className: null,
  style: {},
};
