import { ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import apiClient from 'services/api';

import ProtectedRoute from 'components/protectedRoute';
import Header from 'components/header';
import Footer from 'components/footer';
import { PageLoader } from 'components/loaders';
import { ResponsiveContainer, Layout } from 'components/styled';

import GlobalStyles from 'theme/globalStyles';
import { lightTheme } from 'theme';

import Login from 'views/login';
import Home from 'views/home';
import Questionnaire from 'views/questionnaire';
import Customisation from 'views/customisation';
import PublicReadme from 'views/publicReadme';

function App(): ReactElement {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    apiClient.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Layout>
        <Header />
        <ResponsiveContainer>
          {isLoading ? (
            <PageLoader />
          ) : (
            <Router>
              <Switch>
                <ProtectedRoute exact path="/" component={Home} />
                <ProtectedRoute
                  exact
                  path="/questionnaire"
                  component={Questionnaire}
                />
                <ProtectedRoute
                  exact
                  path="/customisation"
                  component={Customisation}
                />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/public/:readmeId"
                  component={PublicReadme}
                />
              </Switch>
            </Router>
          )}
        </ResponsiveContainer>
        <Footer />
        <GlobalStyles />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
