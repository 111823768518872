import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUpdateAtom } from 'jotai/utils';

import shouldShowErrorAtom from 'store/shouldShowError';
import { Box, Text, Tag, Button, ButtonGroup } from 'components/styled';
import IconButton from 'components/iconButton';
import QuestionItem from 'components/questionItem';
import { PageLoader } from 'components/loaders';
import useActiveQuestionnaire from 'hooks/useActiveQuestionnaire';
import getItemAnswer from 'helpers/getItemAnswer';

import { isQuestionnaireComplete } from './helpers';

export default function Questionnaire(): ReactElement {
  const { t } = useTranslation('questionnaire');
  const setShouldShowError = useUpdateAtom(shouldShowErrorAtom);
  const history = useHistory();
  const questionnaire = useActiveQuestionnaire();

  if (questionnaire.isLoading || questionnaire.isIdle) {
    return <PageLoader />;
  }

  if (questionnaire.isError) {
    return <p>{questionnaire.error.message}</p>;
  }

  const isComplete = isQuestionnaireComplete(
    questionnaire.data.items,
    questionnaire.data.answers,
  );

  const handleReturn = () => {
    history.push('/');
  };

  const handleNext = () => {
    setShouldShowError(true);
    const firstUnanswered = questionnaire.data.items.find(
      (item) => !getItemAnswer(item.id, questionnaire.data.answers),
    );

    if (!isComplete && firstUnanswered) {
      const cardNode = document.getElementById(firstUnanswered.id);
      if (cardNode) {
        window.scrollTo({ behavior: 'smooth', top: cardNode.offsetTop - 10 });
      }

      return null;
    }
    return history.push('/customisation');
  };

  return (
    <div>
      <Box style={{ position: 'relative' }}>
        <IconButton
          icon="arrow-left"
          onClick={handleReturn}
          style={{
            position: 'absolute',
            left: 0,
          }}
        />
        <Tag color="moderateViolet">{t('tag')}</Tag>
        <Text size="sm" type="primary" fontWeight="bold" lineHeight={3}>
          {t('title')}
        </Text>
        <Text size="xxs" type="secondary" fontWeight="normal">
          {t('estimation')}
        </Text>
      </Box>

      {questionnaire.data.items.map((item) => {
        const answer = getItemAnswer(item.id, questionnaire.data.answers);

        return (
          <QuestionItem
            id={item.id}
            key={item.id}
            questionnaireId={questionnaire.data.id}
            item={item}
            answer={answer}
          />
        );
      })}

      <ButtonGroup>
        <IconButton size={46} icon="arrow-left" onClick={handleReturn} />
        <Button variant="primary" fullWidth onClick={handleNext}>
          {t('submit')}
        </Button>
      </ButtonGroup>
    </div>
  );
}
