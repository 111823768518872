import { ReactElement } from 'react';
import { Color, AnswerWithItem } from 'types';
import Card from 'components/card';
import { Text } from 'components/styled';
import { useTranslation } from 'react-i18next';
import AnswersDisplay from './AnswersDisplay';
import AnswersEditor from './AnswersEditor';

type Props = {
  title: string;
  color: Color;
  answersWithItems: AnswerWithItem[];
  isEditMode?: boolean;
};

export default function Section({
  title,
  color,
  answersWithItems,
  isEditMode,
}: Props): ReactElement {
  const { t } = useTranslation('customisation');

  const Answers = isEditMode ? AnswersEditor : AnswersDisplay;

  return (
    <Card title={title} color={color}>
      {isEditMode && (
        <Text type="secondary" size="xxs" style={{ marginBottom: 16 }}>
          {t('section.hint')}
        </Text>
      )}
      <Answers answers={answersWithItems} color={color} />
    </Card>
  );
}

Section.defaultProps = {
  isEditMode: false,
};
