import { Color } from 'types';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { mapStyles } from './helpers';
import { Box as BaseBox } from '../box';

export const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ResponsiveContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: ${(props) => props.theme.space.sm} ${(props) => props.theme.space.xs};

  @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
    width: ${(props) => props.theme.breakpoint.desktop};
    margin: 0 auto;
  }
`;

type TextProps = {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  fontWeight?: 'normal' | 'bold';
  fontFamily?: 'serif' | 'sansSerif';
  type?: 'primary' | 'secondary';
  lineHeight?: number;
};

export const Text = styled.p<TextProps>`
  margin: 0;
  color: ${(props) =>
    mapStyles(props.type, {
      primary: props.theme.color.black,
      secondary: props.theme.color.darkGray,
    })};
  font-family: ${(props) =>
    props.theme.fontFamily[props.fontFamily || 'sansSerif']};
  font-size: ${(props) => props.theme.fontSize[props.size || 'xs']};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: ${(props) => props.lineHeight || 1};
`;

type ButtonProps = {
  variant: 'primary' | 'secondary';
  minWidth?: string;
  fullWidth?: boolean;
};

export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 6px;
  background-color: ${(props) =>
    mapStyles(props.variant, {
      primary: props.theme.color.black,
      secondary: props.theme.color.lightGray2,
    })};
  color: ${(props) =>
    mapStyles(props.variant, {
      primary: props.theme.color.white,
      secondary: props.theme.color.darkGray,
    })};
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSize.xs};
  font-weight: bold;
  padding: ${(props) => props.theme.space.xs};
  cursor: pointer;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  min-width: ${(props) => props.minWidth || 'auto'};
  transition: background-color 0.1s linear;

  & + & {
    margin-left: ${(props) => props.theme.space.xs};
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      mapStyles(props.variant, {
        primary: lighten(0.1, props.theme.color.black),
        secondary: darken(0.1, props.theme.color.lightGray2),
      })};
  }

  &:disabled {
    background-color: ${(props) => props.theme.color.grayBlue2};
  }
`;

export const Tag = styled.div<{ color: Color }>`
  background-color: ${(props) => props.theme.color[props.color]};
  padding: 4px ${(props) => props.theme.space.xs};
  color: ${(props) => props.theme.color.white};
  border-radius: 50px;
  font-size: ${(props) => props.theme.fontSize.xs};
  display: inline-block;

  & + & {
    margin-left: ${(props) => props.theme.space.xxs};
  }
`;

export const Box = styled(BaseBox)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.space.sm};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.sm} 0;

  button + button {
    margin-left: ${(props) => props.theme.space.xs};
  }
`;

export const Graphic = styled.div<{ color: Color }>`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background: ${(props) => lighten(0.3, props.theme.color[props.color])};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkBox = styled.div`
  padding: ${(props) => props.theme.space.sm};
  background: ${(props) => props.theme.color.lightGray};
  border-radius: 6px;
  text-align: center;

  a {
    color: ${(props) => props.theme.color.brightBlue};
  }
`;

export default {};
