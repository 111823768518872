import { ReactElement } from 'react';
import styled from 'styled-components';
import loading from 'assets/images/loading.gif';
import success from 'assets/images/checkmark-icon.svg';
import cross from 'assets/images/cross-icon.svg';

type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

const Status = styled.img`
  position: absolute;
  top: -16px;
  right: 0px;
  width: 16px;
  height: 16px;
`;

export default function AnswerStatus({
  isLoading,
  isSuccess,
  isError,
}: Props): ReactElement {
  if (isLoading) {
    return <Status src={loading} alt="loading" />;
  }

  if (isError) {
    return <Status src={cross} alt="error" />;
  }

  if (isSuccess) {
    return <Status src={success} alt="success" />;
  }

  return <></>;
}
