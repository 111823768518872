import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import { Box, Text, Tag, Button, Graphic, LinkBox } from 'components/styled';
import { PageLoader } from 'components/loaders';
import Card from 'components/card';
import SectionList from 'components/section/SectionList';
import questionaireIcon from 'assets/images/questionaire-icon.svg';
import phrasesIcon from 'assets/images/phrases-icon.svg';
import useReadme from 'hooks/useReadme';
import usePublicReadme from 'hooks/usePublicReadme';

export default function Home(): ReactElement {
  const { t } = useTranslation(['home', 'customisation']);
  const history = useHistory();
  const readme = useReadme();

  const readmeId = readme?.data?.id ?? '';

  const publicReadme = usePublicReadme(readmeId);

  const handleStart = () => {
    history.push('/questionnaire');
  };

  if (readme.isLoading || publicReadme.isLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      {readme.data ? (
        <>
          <Card>
            <Text size="sm" type="primary" fontWeight="bold" lineHeight={2}>
              {t('readmeurl.title')}
            </Text>
            <Text
              size="xs"
              type="secondary"
              fontWeight="normal"
              style={{ marginBottom: 16 }}
            >
              {t('readmeurl.subtitle')}
            </Text>
            <LinkBox>
              <Link
                to={`/public/${readme.data.id}`}
              >{`app.how-to-read.me/public/${readme.data.id}`}</Link>
            </LinkBox>
          </Card>
          {publicReadme.isError && publicReadme.error?.message}
          {publicReadme.isLoading ? (
            'loading...'
          ) : (
            <SectionList
              sections={publicReadme.data?.sections ?? []}
              answers={publicReadme.data?.answers ?? []}
            />
          )}
          <Button
            variant="primary"
            fullWidth
            style={{ marginTop: 16, marginBottom: 16 }}
            onClick={handleStart}
          >
            {t('edit')}
          </Button>
        </>
      ) : (
        <>
          <Box>
            <Text size="md" type="primary" fontWeight="bold" lineHeight={3}>
              {t('title')}
            </Text>
            <Text size="xs" type="secondary" fontWeight="normal">
              {t('subtitle')}
            </Text>
          </Box>
          <Card>
            <Tag color="moderateViolet">{t('step1.tag')}</Tag>
            <Text size="sm" type="primary" fontWeight="bold" lineHeight={2}>
              {t('step1.title')}
            </Text>
            <Text size="xxs" type="secondary" fontWeight="normal">
              {t('step1.estimation')}
            </Text>
            <Graphic color="moderateViolet" style={{ marginTop: 16 }}>
              <img src={questionaireIcon} alt="questionaire" />
            </Graphic>
          </Card>
          <Card>
            <Tag color="strongCyan">{t('step2.tag')}</Tag>
            <Text size="sm" type="primary" fontWeight="bold" lineHeight={2}>
              {t('step2.title')}
            </Text>
            <Text size="xxs" type="secondary" fontWeight="normal">
              {t('step2.estimation')}
            </Text>
            <Graphic color="strongCyan" style={{ marginTop: 16 }}>
              <img src={phrasesIcon} alt="questionaire" />
            </Graphic>
          </Card>
          <Button
            variant="primary"
            fullWidth
            style={{ marginTop: 16, marginBottom: 16 }}
            onClick={handleStart}
          >
            {t('start')}
          </Button>
        </>
      )}
    </div>
  );
}
