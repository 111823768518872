import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { Text } from 'components/styled';

type Props = {
  length: number;
  initialAnswer: number | undefined;
  onAnswer: (index: number) => void;
  disabled: boolean;
};

const ScaleAnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.space.xs};
`;

const ScaleButton = styled.button<{ isActive: boolean }>`
  width: 32px;
  height: 32px;
  margin: 0 4px 0 4px;
  font-size: ${(props) => props.theme.fontSize.md};
  color: ${(props) => props.theme.color.darkGray};
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.color.black};
    color: ${(props) => props.theme.color.white};
  }

  ${(props) =>
    props.isActive &&
    `background-color: ${props.theme.color.black};
    color: ${props.theme.color.white};`}
`;

export default function ScaleAnswer({
  length,
  onAnswer,
  initialAnswer,
  disabled,
}: Props): ReactElement {
  const answerOptions = Array.from(Array(length).keys());
  const [active, setActive] = useState<number | null | undefined>(
    initialAnswer,
  );

  const handleAnswer = useDebouncedCallback((index: number) => {
    onAnswer(index);
  }, 1000);

  const handleClick = (index: number) => {
    if (disabled) {
      return;
    }

    setActive(index);
    // When clicking too quickly, cancel previous executions
    // to prevent multiple of the same actions
    handleAnswer.cancel();
    handleAnswer(index);
  };

  return (
    <ScaleAnswerContainer>
      <Text type="secondary" size="xxs">
        Disagree
      </Text>
      {answerOptions.map((value) => (
        <ScaleButton
          key={value}
          type="button"
          onClick={() => handleClick(value)}
          isActive={active === value}
        >
          {value + 1}
        </ScaleButton>
      ))}
      <Text type="secondary" size="xxs">
        Agree
      </Text>
    </ScaleAnswerContainer>
  );
}
