import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { Readme } from 'types';
import { getPublicReadme } from 'services/questionnaires';

export default function usePublicReadme(
  readmeId: string,
): UseQueryResult<Readme, AxiosError> {
  return useQuery(
    'public-readme',
    () => getPublicReadme(readmeId),
    { enabled: !!readmeId }
  );
}
