import styled from 'styled-components';
import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop';
import {
  space,
  color,
  layout,
  flexbox,
  shadow,
  border,
  grid,
  position,
  background,
  typography,
  system,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  ShadowProps,
  BorderProps,
  GridProps,
  PositionProps,
  BackgroundProps,
  TypographyProps,
} from 'styled-system';

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'cursor',
  'transform',
  'outline',
]) as (prop: string | number) => boolean;

const customThemeBuilder = system({
  transform: true,
  cursor: true,
  textDecoration: true,
  textOverflow: true,
  whiteSpace: true,
  outline: true,
  transition: true,
  filter: true,
  userSelect: true,
});

export type BoxProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  ShadowProps &
  BorderProps &
  GridProps &
  PositionProps &
  BackgroundProps &
  TypographyProps & {
    cursor?: string;
    textDecoration?: string;
    textOverflow?: string;
    whiteSpace?: string;
    outline?: string;
    transition?: string;
    transform?: string;
    filter?: string;
    userSelect?: string;
    type?: string;
    ref?: React.Ref<unknown>;
  };

export const Box = styled.div.withConfig({
  shouldForwardProp,
})<BoxProps>(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  layout,
  grid,
  flexbox,
  shadow,
  border,
  position,
  background,
  typography,
  customThemeBuilder,
);
