import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useThemeGet } from '../../hooks/useThemeGet';

type Props = {
  height?: number;
  width?: number;
  backgroundColor?: string;
  foregroundColor?: string;
  viewBox?: string;
  speed?: number;
};

export const PageLoader = (props: Props): ReactElement => {
  const colors = useThemeGet({ reference: 'colors' }) as Record<string, string>;
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      height={400}
      width="100%"
      foregroundColor={colors.gray}
      backgroundColor={colors.lightGray2}
      {...props}
    >
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="400" height="8" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="79" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
    </ContentLoader>
  );
};
