import { createGlobalStyle } from 'styled-components';

import theme from './index';

const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    padding:0;
    background-color: ${theme.color.grayBlue};
    color: ${theme.color.black};
    font-size: ${theme.fontSize.sm};
    font-family: ${theme.fontFamily.sansSerif};
  }

  * {
    box-sizing: border-box;
  }

  @keyframes twinkle {
    50% {
      transform: scale(0.7);
      opacity: 0.5;
    }
  }
`;

export default GlobalStyle;
