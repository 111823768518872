import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { updateAnswer } from 'services/questionnaires';
import { Answer, AnswerPayload, AnswerWithItem } from 'types';

type ErrorCallback = (error: AxiosError) => void;
type SuccessCallback = (data: Answer) => void;

export default function useUpdateAnswer(
  answer: AnswerWithItem,
  onSuccess: SuccessCallback,
  onError?: ErrorCallback,
): UseMutationResult<Answer, AxiosError, AnswerPayload> {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AnswerPayload) =>
      updateAnswer(
        answer.questionnaire_id,
        answer.item.id,
        payload,
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('customisation')
        onSuccess(data);
      },
      onError: (error: AxiosError) => onError && onError(error),
    },
  );
}
