import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { postAnswer } from 'services/questionnaires';
import { Answer, AnswerPayload } from 'types';

export default function usePostAnswer(
  questionnaireId: string,
  itemId: string,
): UseMutationResult<Answer, AxiosError, AnswerPayload> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => postAnswer(questionnaireId, itemId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('questionnaire');
      },
    },
  );
}
