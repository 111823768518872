import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { getActiveQuestionnaire } from 'services/questionnaires';
import { ActiveQuestionnaire } from 'types';

export default function useActiveQuestionnaire(): UseQueryResult<
  ActiveQuestionnaire,
  AxiosError
> {
  return useQuery('questionnaire', getActiveQuestionnaire);
}
