import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { setAnswersOrder } from 'services/questionnaires';
import { Answer, Readme } from 'types';

export default function useUpdateAnswer(
  questionnaireId: string,
): UseMutationResult<Answer[], AxiosError, string[]> {
  const queryClient = useQueryClient();
  return useMutation((payload) => setAnswersOrder(questionnaireId, payload), {
    onMutate: async (answerOrder) => {
      await queryClient.cancelQueries('customisation');
      const previous = queryClient.getQueryData('customisation') as Readme;

      const nextAnswers = previous.answers.sort(
        (a, b) => answerOrder.indexOf(a.id) - answerOrder.indexOf(b.id),
      );

      // Optimistically update to the new value
      queryClient.setQueryData('customisation', (prev) => ({
        ...(prev as Readme),
        answers: nextAnswers,
      }));

      return { previous };
    },
    onSettled: () => {
      queryClient.invalidateQueries('customisation');
    },
  });
}
