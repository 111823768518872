import { ReactElement } from 'react';
import { AnswerWithItem, Section as SectionType } from 'types';
import Section from 'components/section';
import getNextSectionColor from 'helpers/getNextSectionColor';

type Props = {
  sections: SectionType[];
  answers: AnswerWithItem[];
  isEditMode?: boolean;
};

export default function SectionList({
  sections,
  answers,
  isEditMode,
}: Props): ReactElement {
  const sectionsWithAnswers = sections.filter((section) =>
    answers?.find((answer) => answer.item.section_id === section.id),
  );

  return (
    <>
      {sectionsWithAnswers.map(({ id, text }, index) => (
        <Section
          key={id}
          title={text}
          color={getNextSectionColor(index)}
          answersWithItems={answers.filter(
            (answer) => answer.item.section_id === id,
          )}
          isEditMode={isEditMode}
        />
      ))}
    </>
  );
}

SectionList.defaultProps = {
  isEditMode: false,
};
